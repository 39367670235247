import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Portals from '../Portals';
import Whatisthis from 'components/Whatisthis';
import Homecta from '../Homecta';
import Footer from '../Footer';

function Home () {
    return (
        <>
          <HeroSection />
          <Portals />
          <Whatisthis />
          <Homecta />
          <Footer />
        </>
    )
}

export default Home;