import React from "react";
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import './App.css';

export default function Best() {
    return <div>
        <div className='inner__page__container'>
            <div className='inner__content__container'>
                <h1>Is React Perfect?</h1>
                <p><center>No.</center></p>
                <h1>Who React Is Great For</h1>
                <p>Simply put, developers that want to build complex interfaces quickly and in a manner that can be scaled far beyond what even the world's most complex CMS systems allow for.</p>
                <p>If you get up every morning and worship at the holy alter of Javascript, you'll love it.</p>
                <p>You can build amazing things, bolt heavy logic right into it without there being a separate app, intergrate the databases, NodeJS, and Python pretty easily, build your own APIs, etc.</p>
                <h1>Who React Is Not Great For</h1>
                <p>It's not a very good solution for clients that want to run their own websites, or their own eCommerce solutions quickly and easily. WordPress is still the best alternative there.</p>
                <p>[Editors Note: React would be great as a front-end running WordPress as a headless CMS. I will be looking into that in the future.]</p>
                <p>For the most part you can really use anything for clients with needs to self manage... as long as it's not .net. :) We will talk about you in unkind ways if you use that.</p>
                <h1>Ready To Talk About Your Development Needs?</h1>
            </div>
            <a href="https://www.brianmccracken.com/work-with-brian/" className="inner-page-button">Contact Brian</a> 
        </div>
        <Footer />
    
    
    
    </div>;
}