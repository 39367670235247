import React from 'react';
import '../App.css';
import './HeroSection.css';
import './Button.css';


function HeroSection() {
    return (
        <div className='hero-container'>
            <h1>ADVENTURE AWAITS</h1>
            <p>Welcome to my ReactJS Portfolio</p>
            <div className="hero-btns">
                <a href="https://www.brianmccracken.com/work-with-brian/" className='first-button'>Contact Brian</a>
                <a href="https://github.com/CleWebDev/CleWebDev" className='second-button'>Download Source</a>
            </div>
        </div>
    )
}

export default HeroSection;
