import React from 'react'
import './Portals.css';
import { Link } from 'react-router-dom';

function portals() {
    return (
        <div className="section__wrapper">
            <div className="section__intro">
                <h2>Frequently Ased Questions</h2>
                    <div className="portal-flexbox">
                        <Link to='/what' className="portal__item">
                        <h1>What Is React?</h1>
                            <p>The very simple explaination.</p>
                            <img src="./logo192-2.webp" width="50px" height="50px" alt="react-logo" />
                        </Link>
                        <Link to='/why' className="portal__item">
                            <h1>Why Use React?</h1>
                            <p>Is it better than say, WordPress?</p>
                            <img src="./logo192-2.webp" width="50px" height="50px" alt="react-logo" />
                        </Link>
                        <Link to='/best' className="portal__item">
                            <h1>Is It Perfect?</h1>
                            <p>Is React ALWAYS the right choice?</p>
                            <img src="./logo192-2.webp" width="50px" height="50px" alt="react-logo" />
                        </Link>
                    </div>
            </div>
        </div>
    )
}

export default portals;
