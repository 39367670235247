import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Pages/Home.js';
import What from './components/Pages/What.js';
import Why from './components/Pages/Why.js';
import Best from './components/Pages/Best.js';
import Changelog from './components/Pages/Changelog.js';
import ScrollToTop from './components/ScrollToTop.js';
import "@fontsource/pt-sans";

function App() {
  return (
    <>
      <Router>
      <Navbar />
        <ScrollToTop />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/what' exact component={What} />
            <Route path='/why' exact component={Why} />
            <Route path='/best' exact component={Best} />
            <Route path='/changelog' exact component={Changelog} />
          </Switch>
      </Router>
    </>
  );
}

export default App;
