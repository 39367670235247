import React from 'react';
import './Homecta.css';

function Homecta () {
    return (
        <>
            <div className="homectw_wrapper">
                <h3>New Projects Will Be Added Here As They Are Created</h3>
                <div className="homecta_copy">   
                    <p>Until we get to that point, here are some other ways to reach me and initiate a conversation about your upcoming development or marketing needs.</p>
                </div>
                <div className="homecta_cards_holder">
                    <div className="homecta_cards">
                        <img src="../../images/linkedin2.webp" alt="linkedin-banner" />
                        <h4>Connect On LinkedIn</h4>
                        <p>Be sure to send me a message and mention that you have seen the ReactJS site.</p>
                        <a href="https://www.linkedin.com/in/brian-mccracken/" className="homecta_cards_button">Click Here To Visit</a>
                    </div>
                    <div className="homecta_cards">
                        <img src="../../images/website.webp" alt="website-banner" />
                        <h4>Contact Me Directly</h4>
                        <p>Please use the contact form on my main site to get ahold of me.</p>
                        <a href="https://www.brianmccracken.com/work-with-brian/" className="homecta_cards_button">Click Here To Visit</a>
                    </div>
                    <div className="homecta_cards">
                        <img src="../../images/github.webp" alt="github-banner" />
                        <h4>Download My Source Code</h4>
                        <p>If you are a hiring manager interested in auditing my work, you can find the source code for this application on GitHub!</p>
                        <a href="https://github.com/CleWebDev/CleWebDev" className="homecta_cards_button">Download Now</a>
                    </div>



                </div>
            </div>
        </>
    )
}

export default Homecta;