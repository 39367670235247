import React from "react";
import Footer from '../Footer';
import './App.css';

export default function Changelog() {
    return <div> 
        <div className='inner__page__container'>
            <div className='inner__content__container'>
                <h1>CHANGELOG</h1>
                <p>September 10th, 2021 - Initial Release</p>
                <p>v1.01 - Add ScrollToTop functionality needing during page naviagation.</p>
                <p>v1.02 - Add _redirect file to public to maintain inner URL routing. Correct copy on affected pages.</p>
                <p>September 12th, 2021</p>
                <p>v1.03 - webp images used in place of jpg/png. Alt tags added for accessibility. Google Fonts hosted and served locally.</p>
                <p>v1.04 - FontAwesome dependancy replaced with webp images, site now scores a perfect 100 on Google's PageSpeed Insights for both MOBILE and DESKTOP, and a 100 across the board on all GTMetrix benchmarks as well.</p>
                <p>Total page size is now between 210-320kb per page. Since the initial release, the homepage size has been reduced dramatically (1.1mb -> 320kb) to no loss of functionality. A future rebuild will decrease the number of React repositories used to further shrink bundle.js size.</p>
                <h1>ROADMAP</h1>
                <p>Reduce CSS, streamline viewport rendering.</p>
                <p>Introduce and use Framer Motion animation library.</p> 
                <p>Introduce and use Animate Presnece for page transitions.</p>           
            </div>
        </div>
        <Footer />
    
    
    
    </div>;
}