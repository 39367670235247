import React from 'react';
import './Whatisthis.css';

function Whatisthis () {
    return (
        <div className="whatis_container">
            <div className="left">
                <div className="svg__holder">
                    <img src="./logo.svg" className="App-logo" alt="logo" />
                </div>    
            </div>
            <div className="right">
            <h3>What's under the hood?</h3>
                    <p>The base for the entire build of this portfolio itself is ReactJS, but deployed as a complete multiple page website using React Router versus a SPA.</p>
                    <h3>What's React Router?</h3>
                    <p>React Router enables the navigation among views of various components in a React Application, allows changing the browser URL, and keeps the UI in sync with the URL.</p>
                    <h3>How is this deployed?</h3>
                    <p>You are currently looking at a v1.0 product with a pipeline from development to GIT, and another pipeline built from GIT to the hosting enviroment. As charges are pushed from development to GIT, the hosting enviroment automatically moves those changes to production.</p>
            </div>
        </div>
    )
}

export default Whatisthis;