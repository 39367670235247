import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './Assets/signature-react2.webp';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize', showButton)
    return (
        <>
            <nav className="navbar">
             <div className="navbar-container">
              <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                <img src={logo} alt="Logo" />
              </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <img src={click ? '../../times.webp' : '../../bars.webp'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                        <Link to='/what' className='nav-links' onClick={closeMobileMenu}>
                            What
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/why' className='nav-links' onClick={closeMobileMenu}>
                            Why
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Changelog' className='nav-links' onClick={closeMobileMenu}>
                            Changelog
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <a href="https://www.brianmccracken.com/" className='nav-links-mobile' onClick={closeMobileMenu}>
                            BM.com
                        </a>
                    </li>
                </ul>
                {button && <a href="https://www.brianmccracken.com/" className='btn--outline'>BM.com</a>}
             </div>
             </nav> 
        </>
    )
}

export default Navbar
