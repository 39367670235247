import React from "react";
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import './App.css';

export default function Why() {
    return <div>
        <div className='inner__page__container'>
            <div className='inner__content__container'>
                <h1>Why Use React?</h1>
                    <p>React allows developers to create large web applications that can change data, without reloading the page. The main purpose of React is to be fast, scalable, and simple. It works only on user interfaces in the application. This corresponds to the view in the MVC template. It can be used with a combination of other JavaScript libraries or frameworks, such as Angular JS in MVC.</p>
                    <h1>But Really.... WHY?</h1>
                    <p>All of that above, it just doesn't make sense for most traditional developers.</p>
                    <p>Let's talk, navigation. When you go from one URL to the next on most websites, it has to load a completely new page. That means the header, footer, sidebars, etc. Sure the CSS may be cached, but all of that HTML comes along for the ride.</p>
                    <p>When you move from page to page in react, you aren't actually moving at all. You see, it's not like a static website that serves the page and then goes onto something else. It's an application that is always running. So when you move to a new page, nothing reloads. The application takes your existing page compares it to the newly requested page, calculates the difference between the two, then modifies the virtual DOM to render the new changes, which is why it's so quick. It's not reloading the header, footer, etc... anything else that might normally come with a new page request on a traditional static website.</p>
                    <h1>Virtual DOM?</h1> 
                    <p>Yes, I know that might sound confusing at first, but the application is always running and anticipating changes. It provides your browser or device a DOM for the page, but it's not set in stone. The application can change that DOM at any point.</p>       
                    <p>Think about being on Facebook, or Instagram. You've probably seen the like counts, or new comments just appear on page. That's React running that. It changes the elements in the DOM with new information, instantly, and without complicated API requests. It's fast, and super efficient.</p>

            </div>
            <Link to='/best' className="inner-page-button">Next: Is It Perfect?</Link> 
        </div>
        <Footer />
    </div>
}